import React, {useRef, useEffect} from 'react';
import { InlineWidget } from "react-calendly";

import Layout from "../components/layout"
import Seo from "../components/seo"



//sections

const CallWithMohi = () => {
  
  const ref = useRef(null);

  useEffect(() => {
    const el2 = ref.current;
    console.log(el2);

  },[]);

  return (
    <Layout>
      <Seo title="Call With Mohi" />
      <div className="page-wrapper callwithmohi-page" id="callwithmohi-page-wrapper">
        <section className="call-with-mohi">
            <div className="container">
                <div className="row">
                    <div className="col-lg-21">
                        <div className="title-block">
                            <p className="extra-large section-title extra-spacing">Tell us about your project and your objectives, and we'll respond to you within 24 to 48 hours of receiving the inquiry.</p>
                        </div>
                        <div className="booking-block">
                            <InlineWidget 
                              url="https://calendly.com/decojent" 
                              styles={{
                                height: '900px',
                                width: "100%",
                              }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </div>
    </Layout>
  )
}

export default CallWithMohi;

